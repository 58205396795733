import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import AppHeader from '../../../components/header'
import LoadingSpinner from '../../../components/loading'
import Table from '../../../components/table'

const ShowReference = () => {
	const { id } = useParams()
	const [reference, setReference] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const navigate = useNavigate()

	useEffect(() => {
		const URL = `${process.env.REACT_APP_API_URL}/reference/${id}`

		fetch(URL)
			.then((response) => response.json())
			.then((data) => {
				const { reference } = data
				setReference(reference)
				setIsLoading(false)
			})
	}, [id])

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-screen min-w-full bg-gray-100 bg-opacity-50">
				<LoadingSpinner />
			</div>
		)
	}

	return (
		<>
			<AppHeader />
			<div className="flex w-25 ml-2">
				<button
					onClick={() => navigate(-1)}
					className="flex w-25 my-2 justify-center rounded-full bg-blue-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
				>
					Volver
				</button>
			</div>
			<div className="m-auto flex justify-center align-center">
				<Table data={reference['activists']} activists={true} />
			</div>
		</>
	)
}

export default ShowReference
