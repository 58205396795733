import React, { useEffect, useState } from 'react'

import AppHeader from '../../../components/header'
import LoadingSpinner from '../../../components/loading'
import Table from '../../../components/table'
import { useNavigate } from 'react-router-dom'

const IndexReference = () => {
	const [reference, setReference] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const navigate = useNavigate()

	useEffect(() => {
		const URL = `${process.env.REACT_APP_API_URL}/reference`
		setIsLoading(true)
		fetch(URL)
			.then((response) => response.json())
			.then((data) => {
				const { references } = data
				setReference(references)
				setIsLoading(false)
			})
	}, [])

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-screen min-w-full bg-gray-100 bg-opacity-50">
				<LoadingSpinner />
			</div>
		)
	}

	return (
		<>
			<AppHeader />
			<div className="flex mr-2 justify-end ">
				<button
					onClick={() => navigate('/admin/referentes/crear')}
					className="flex justify-center items-center  w-60 my-2 justify-center rounded-full bg-blue-300 px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
				>
					Crear referente
					<svg
						width="20px"
						height="20px"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
					>
						<path
							d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15"
							stroke="#ffffff"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
						<path
							d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
							stroke="#ffffff"
							strokeWidth="1.5"
							strokeLinecap="round"
						/>
					</svg>
				</button>
			</div>
			<div className="m-auto flex justify-center align-center">
				<Table data={reference} />
			</div>
		</>
	)
}

export default IndexReference
