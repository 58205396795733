import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import FormActivist from '../../components/formActivist'
import LoadingSpinner from '../../components/loading'
import VerificationInput from 'react-verification-input'
import axios from 'axios'
import logo_completo from '../../assets/logo_completo.png'
import { toast } from 'react-toastify'

const IndexActivist = () => {
	const navigate = useNavigate()
	// const { referent } = useParams()
	const [name, setName] = useState()
	const [surname, setSurname] = useState()
	const [email, setEmail] = useState()
	const [address, setAddress] = useState()
	const [phone, setPhone] = useState()
	const [referentName, setReferentName] = useState('')
	const [isValidated, setIsValidated] = useState(false)
	const [code, setCode] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [references, setReferences] = useState([])
	const [cityId, setCityId] = useState(0)
	const [stateId, setStateId] = useState(0)
	const [district, setDistrict] = useState('')
	const [group, setGroup] = useState('')
	const params = useParams()

	const handlerValidate = () => {
		const URL = `${process.env.REACT_APP_API_URL}/activist/activate`
		setIsLoading(true)
		axios
			.post(URL, { email, code })
			.then((res) => {
				toast.success('Registro validado con éxito', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
				setIsLoading(false)
				setIsValidated(false)
				navigate('/activist/success')
			})
			.catch((err) => {
				toast.error('Código de validación incorrecto', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				})
				setIsLoading(false)
			})
	}

	useEffect(() => {
		const getReferences = async () => {
			await axios
				.get(`${process.env.REACT_APP_API_URL}/reference/all`)
				.then((res) => {
					setReferences(res.data.references)
				})
		}

		try {
			getReferences()
		} catch (error) {
			console.log(error)
		}
	}, [])

	const render = {
		form: (
			<>
				<div className="w-full flex justify-center">
					<img src={logo_completo} alt="" className="h-36" />
				</div>
				<FormActivist
					name={{ value: name, set: setName }}
					last_name={{ value: surname, set: setSurname }}
					email={{ value: email, set: setEmail }}
					address={{ value: address, set: setAddress }}
					phone={{ value: phone, set: setPhone }}
					referentName={{ value: referentName, set: setReferentName }}
					isValidated={{ value: isValidated, set: setIsValidated }}
					cityId={{ value: cityId, set: setCityId }}
					stateId={{ value: stateId, set: setStateId }}
					district={{ value: district, set: setDistrict }}
					group={{ value: group, set: setGroup }}
					block={params['referent'] ? true : false}
					references={references}
					setIsValidated={setIsValidated}
				/>
			</>
		),
		validate: (
			<div className="flex flex-col justify-center align-center w-full">
				<h1 className="text-2xl text-center">Validación</h1>
				<span className="p-2 m-2">
					Se ha enviado un código de validación a su correo electrónico {email}
				</span>

				<div className="flex justify-center align-center">
					<VerificationInput
						length={6}
						placeholder="-"
						onChange={(e) => {
							setCode(e)
						}}
						classNames={{
							container:
								'flex justify-center align-center m-4 focus:border-white-500',
							character: 'border-gray-300 rounded-xl',
							characterSelected: 'border-gray-300 rounded-xl',
						}}
					/>
				</div>

				<button
					type="button"
					onClick={handlerValidate}
					className="inline-flex justify-center rounded-md border border-transparent bg-blue-400 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 m-2"
				>
					Validar
				</button>
			</div>
		),
	}

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-screen min-w-full bg-gray-100 bg-opacity-50">
				<LoadingSpinner />
			</div>
		)
	}

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center py-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-xl">
					<div className="rounded-2xl shadow-2xl p-6 border border-gray-100 bg-gray-100">
						{isValidated ? render['validate'] : render['form']}
					</div>
				</div>
			</div>
		</>
	)
}

export default IndexActivist
