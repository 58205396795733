import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'

import Cookies from 'universal-cookie'
import { Dialog } from '@headlessui/react'
import logo from '../../assets/logo.png'
import logo_completo from '../../assets/logo_completo.png'
import { setUser } from '../../redux/userSlice'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const AppHeader = () => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
	const user = useSelector((state) => state.user.user)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handlerLogout = () => {
		dispatch(setUser(null))
		const cookies = new Cookies()
		cookies.remove('user')
		navigate('/login')
	}

	return (
		<header className="bg-white drop-shadow-xl sticky top-0 rounded">
			<nav
				className="mx-auto flex max-w-7xl items-center justify-between p-2 lg:px-4"
				aria-label="Global"
			>
				<div className="flex">
					<button
						type="button"
						className="m-2.5 inline-flex items-center justify-center rounded-md text-gray-700"
						onClick={() => setMobileMenuOpen(true)}
					>
						<span className="sr-only">Open menu</span>
						<Bars3Icon className="h-6 w-6" aria-hidden="true" />
					</button>
				</div>
				<div className="flex lg:flex-1 mx-2">
					<img className="h-16 w-auto" src={logo_completo} alt="" />
					{/* <img className="h-16 w-auto hidden md:block" src={texto} alt="" /> */}
				</div>

				<div className="hidden lg:flex lg:flex-1 lg:justify-end">
					{user && (
						<button
							onClick={handlerLogout}
							className="text-sm font-semibold leading-6 text-gray-900"
						>
							Cerrar sesión <span aria-hidden="true">&rarr;</span>
						</button>
					)}
				</div>
			</nav>
			<Dialog
				as="div"
				// className="lg:hidden"
				open={mobileMenuOpen}
				onClose={setMobileMenuOpen}
			>
				<div className="fixed inset-0 z-10" />
				<Dialog.Panel className="fixed inset-y-0 left-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
					<div className="flex items-center justify-between">
						<img className="h-8 w-auto" src={logo} alt="" />
						<button
							type="button"
							className="-m-2.5 rounded-md p-2.5 text-gray-700"
							onClick={() => setMobileMenuOpen(false)}
						>
							<span className="sr-only">Close menu</span>
							<XMarkIcon className="h-6 w-6" aria-hidden="true" />
						</button>
					</div>
					<div className="mt-6 flow-root">
						<div className="my-6 divide-y divide-gray-500/10">
							<div className="py-6">
								<button
									onClick={() => navigate('/admin')}
									className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
								>
									Inicio
								</button>
								<button
									onClick={() => navigate('/admin/referentes')}
									className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
								>
									Referentes
								</button>
								<button
									onClick={() => navigate('/admin/afiliados')}
									className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
								>
									Mapa de Afiliados
								</button>
							</div>
						</div>
						<div className="my-6 divide-y divide-gray-500/10">
							<div className="py-6">
								<button
									onClick={handlerLogout}
									className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
								>
									Cerrar sesión
								</button>
							</div>
						</div>
					</div>
				</Dialog.Panel>
			</Dialog>
		</header>
	)
}

export default AppHeader
