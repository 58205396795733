import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'

import AppHeader from '../../components/header'
import { Dialog } from '@headlessui/react'
import { Link } from 'react-router-dom'
import LoadingSpinner from '../../components/loading'
import Table from '../../components/table'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const IndexAdmin = () => {
	const navigate = useNavigate()
	const user = useSelector((state) => state.user.user)
	const [activist, setActivist] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [isOpen, setIsOpen] = useState(false)
	const [selected, setSelected] = useState({})
	const [selectedActivist, setSelectedActivist] = useState({})
	const [query, setQuery] = useState('')
	const [queryActivist, setQueryActivist] = useState('')
	const [references, setReferences] = useState([])

	const referencesToShow =
		query === ''
			? references
			: references.filter((person) => {
					let name = person.name + ' ' + person.last_name
					return name
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(query.toLowerCase().replace(/\s+/g, ''))
			  })

	useEffect(() => {
		const URL = `${process.env.REACT_APP_API_URL}/activist`

		fetch(URL)
			.then((response) => response.json())
			.then((data) => {
				const { activists } = data
				setIsLoading(false)
				setActivist(activists)
			})
	}, [])

	const activistToShow =
		queryActivist === ''
			? activist
			: activist.filter((person) => {
					let name = person.name + ' ' + person.last_name
					return name
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(queryActivist.toLowerCase().replace(/\s+/g, ''))
			  })

	const handlerOnOpen = () => {
		// setIsLoading(true)
		fetch(`${process.env.REACT_APP_API_URL}/reference/all`)
			.then((response) => response.json())
			.then((data) => {
				const { references } = data
				setReferences(references)
				// setIsLoading(false)
				setIsOpen(true)
			})
	}

	const handlerOnSend = () => {
		const URL = `${process.env.REACT_APP_API_URL}/activist/add-reference`
		const data = {
			activist_id: selectedActivist,
			reference_id: selected.id,
		}

		axios
			.post(URL, data)
			.then((response) => {
				console.log(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-screen min-w-full bg-gray-100 bg-opacity-50">
				<LoadingSpinner />
			</div>
		)
	}

	if (!user) {
		navigate('/login')
		return null
	}

	return (
		<>
			<AppHeader />
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={() => setIsOpen(false)}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full min-w-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md h-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
									<Dialog.Title
										as="h3"
										className="text-lg font-medium leading-6 text-gray-900"
									>
										Asociar referente
									</Dialog.Title>
									<div className="mt-2">
										<Combobox value={selected} onChange={setSelected}>
											<div className="relative mt-1">
												<div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
													<Combobox.Input
														className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
														displayValue={(person) =>
															person.name &&
															person.last_name &&
															person.name + ' ' + person.last_name
														}
														placeholder="Seleccione un referente"
														onChange={(event) => setQuery(event.target.value)}
													/>
													<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
														<ChevronUpDownIcon
															className="h-5 w-5 text-gray-400"
															aria-hidden="true"
														/>
													</Combobox.Button>
												</div>
												<Transition
													as={Fragment}
													leave="transition ease-in duration-100"
													leaveFrom="opacity-100"
													leaveTo="opacity-0"
													afterLeave={() => setQuery('')}
												>
													<Combobox.Options className="mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
														{referencesToShow.length === 0 && query !== '' ? (
															<div className="relative cursor-default select-none py-2 px-4 text-gray-700">
																No hay resultados.
															</div>
														) : (
															referencesToShow.map((person) => (
																<Combobox.Option
																	key={person.id}
																	className={({ active }) =>
																		`relative cursor-default select-none py-2 pl-10 pr-4 ${
																			active
																				? 'bg-blue-300 text-white'
																				: 'text-gray-900'
																		}`
																	}
																	value={person}
																>
																	{({ selected, active }) => (
																		<>
																			<span
																				className={`block truncate ${
																					selected
																						? 'font-medium'
																						: 'font-normal'
																				}`}
																			>
																				{person.name} {person.last_name}
																			</span>
																			{selected ? (
																				<span
																					className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
																						active
																							? 'text-white'
																							: 'text-teal-600'
																					}`}
																				>
																					<CheckIcon
																						className="h-5 w-5"
																						aria-hidden="true"
																					/>
																				</span>
																			) : null}
																		</>
																	)}
																</Combobox.Option>
															))
														)}
													</Combobox.Options>
												</Transition>
											</div>
										</Combobox>
									</div>

									<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
											onClick={() => {
												handlerOnSend()
												setIsOpen(false)
											}}
										>
											Asociar
										</button>
										<button
											type="button"
											onClick={() => setIsOpen(false)}
											className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
										>
											Cancelar
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
			<div className="m-auto flex flex-col justify-center align-center items-center">
				<div className="max-w-lg w-4/5 lg:w-2/6 mx-auto">
					<div
						style={{ backgroundColor: '#f4f4f4', opacity: '0.8' }}
						className="flex flex-col self-center justify-between w-full h-42 m-auto mt-4 border border-gray-300 rounded-xl shadow-lg p-4"
					>
						<div className="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden my-2">
							<input
								className="peer h-full w-full outline-none border-0 text-sm text-gray-600 pr-2 focus:border-0 focus:ring-0"
								type="text"
								id="search"
								placeholder="Buscar afiliado..."
								onChange={(event) => setQueryActivist(event.target.value)}
							/>
							<div className="grid place-items-center h-full w-12 text-gray-200">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="grey"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
									/>
								</svg>
							</div>
						</div>
						<h3 className="font-bold">
							Total Afiliados:{' '}
							<span className="font-semibold">{activist.length}</span>
						</h3>
						<Link
							to={`${process.env.REACT_APP_API_URL}/activist/export`}
							target="_blank"
							download
							className="self-center underline"
						>
							Exportar a Excel
						</Link>
					</div>
				</div>
				<Table
					data={activistToShow}
					activists={true}
					isOpen={{ value: isOpen, set: handlerOnOpen }}
					setSelected={setSelectedActivist}
				/>
			</div>
		</>
	)
}

export default IndexAdmin
