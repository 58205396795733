import { useNavigate } from 'react-router-dom'

const Table = ({ data, activists, isOpen, setSelected }) => {
	const navigate = useNavigate()
	return (
		<div role="list" className="divide-y divide-gray-100 w-11/12 ">
			{/* <> */}
			{data.map((person, index) => (
				<li
					key={person.id}
					className="flex justify-between gap-x-6 my-5 rounded-lg p-2 shadow-xl"
					style={{
						backgroundColor:
							index % 2 === 0
								? 'rgba(228,228,231,0.9)'
								: 'rgb(212,212,216,0.9)',
					}}
				>
					<div className="flex min-w-0 gap-x-4">
						<div className="min-w-0 flex-auto">
							<p className="text-lg font-semibold leading-6 text-gray">
								{person.name} {person.last_name}
							</p>
							<p className="mt-1 truncate text-md leading-5 text-gray-600">
								{person.email}
							</p>
							<p className="mt-1 truncate text-md leading-5 text-gray-600">
								{person.phone ? person.phone : 'Sin teléfono'}
							</p>
							<p className="mt-1 truncate text-md leading-6 text-gray-600">
								{person.full_address}
							</p>
						</div>
					</div>
					{activists && !person.reference_id && (
						<div className="flex min-w-0 gap-x-4">
							<div className="min-w-0 flex-auto">
								<p className="text-lg font-semibold leading-6 text-gray">
									Referente cargado por afiliado: {person.reference_name}
								</p>
							</div>
							<div
								className="w-6 m-4 flex justify-center content-center self-center"
								onClick={() => {
									setSelected(person.id)
									isOpen.set(true)
								}}
								title="Asignar referente"
							>
								<svg
									width="20px"
									height="20px"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									style={{ cursor: 'pointer' }}
								>
									<path
										d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15"
										stroke="#1C274C"
										strokeWidth="1.5"
										strokeLinecap="round"
									/>
									<path
										d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
										stroke="#1C274C"
										strokeWidth="1.5"
										strokeLinecap="round"
									/>
								</svg>
							</div>
						</div>
					)}

					{activists && person.reference_id && person.reference && (
						<div className="flex min-w-0 gap-x-4">
							<div className="min-w-0 flex-auto">
								<p className="text-lg font-semibold leading-6 text-gray">
									Referente asignado: {person.reference.name}{' '}
									{person.reference.last_name}
								</p>
								<p className="mt-1 truncate text-md leading-5 text-gray-600">
									Referente cargado por afiliado: {person.reference_name}
								</p>
							</div>
						</div>
					)}
					{!activists && (
						<div className="sm:flex sm:flex-row sm:items-center">
							<div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end w-48">
								{!activists && (
									<p className="mt-1 text-md font-semibold leading-5 text-gray-800">
										{person.activists.length} Afiliados
									</p>
								)}
							</div>
							{!activists && (
								<div className="w-6 m-4">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
										onClick={() => navigate(`/admin/referentes/${person.id}`)}
										style={{ cursor: 'pointer' }}
									>
										<path d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"></path>
									</svg>
								</div>
							)}
						</div>
					)}
				</li>
			))}
		</div>
		// </ul>
	)
}

export default Table
