import React from 'react'
import logo_completo from '../assets/logo_completo.png'

const Index = () => {
	return (
		<div className="min-h-screen flex flex-col justify-center items-center">
			<div className="relative sm:max-w-xl sm:mx-auto w-2/3">
				<div
					className="absolute inset-0 bg-gradient-to-r from-yellow-300 to-blue-300 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"
					style={{ opacity: '0.6' }}
				></div>
				<div className="relative px-4 py-10 bg-white bg-opacity-50 shadow-lg sm:rounded-3xl sm:p-16">
					<div className="max-w-md mx-auto flex justify-center flex-col">
						<div className="my-4 self-center">
							<img alt="" src={logo_completo} width={350} />
						</div>
						<div className="flex justify-around w-full my-4">
							<div className="flex justify-center w-full">
								<a
									href="/activist"
									className="flex w-3/4 justify-center rounded-md bg-blue-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
								>
									Unite
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Index
