import './styles.css'

import React from 'react'

const LoadingSpinner = () => {
	return (
		<div className="spinner-container">
			<div className="loading-spinner"></div>
		</div>
	)
}

export default LoadingSpinner
