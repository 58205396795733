import 'react-toastify/dist/ReactToastify.css'

import { useLocation, useNavigate } from 'react-router-dom'

import LoadingSpinner from '../../../components/loading'
import axios from 'axios'
import logo_completo from '../../../assets/logo_completo.png'
import { toast } from 'react-toastify'
import { useState } from 'react'

const FirstLogin = () => {
	const navigate = useNavigate()
	const { email } = useLocation().state || { email: null }
	const [password, setPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const handlerOnClick = (event) => {
		event.preventDefault()
		setIsLoading(true)

		const URL = `${process.env.REACT_APP_API_URL}/change-password`

		axios
			.post(URL, {
				email,
				password,
				new_password: newPassword,
				confirm_password: confirmPassword,
			})
			.then((response) => {
				setIsLoading(false)
				toast.success('Felicidades, la contraseña ha sido cambiada con exito', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				})

				navigate('/login')
			})
			.catch((error) => {
				setIsLoading(false)
				toast.error('Error, por favor intente nuevamente', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				})
			})
	}

	if (email == null) {
		navigate('/login')
	}

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-screen min-w-full bg-gray-100 bg-opacity-50">
				<LoadingSpinner />
			</div>
		)
	}

	return (
		<div className="min-h-screen flex flex-col justify-center">
			<div className="relative sm:max-w-xl sm:mx-auto">
				<div className="absolute inset-0 bg-opacity-50 bg-gradient-to-r from-yellow-300 to-blue-300 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
				<div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-16">
					<div className="max-w-md mx-auto">
						<div style={{ width: 350 }}>
							<img alt="" src={logo_completo} />
						</div>
						<div className="divide-y divide-gray-200">
							<div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
								<div>
									<div className="flex items-center justify-between">
										<label
											htmlFor="password"
											className="block text-sm font-medium leading-6 text-gray-900"
										>
											Contraseña actual
										</label>
									</div>
									<input
										id="password"
										name="password"
										type="password"
										autoComplete="current-password"
										required
										onChange={(event) => setPassword(event.target.value)}
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
									/>
								</div>
								<div>
									<div className="flex items-center justify-between">
										<label
											htmlFor="password"
											className="block text-sm font-medium leading-6 text-gray-900"
										>
											Nueva Contraseña
										</label>
									</div>
									<input
										id="new_password"
										name="new_password"
										type="password"
										autoComplete="current-password"
										required
										onChange={(event) => setNewPassword(event.target.value)}
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
									/>
								</div>
								<div>
									<div className="flex items-center justify-between">
										<label
											htmlFor="password"
											className="block text-sm font-medium leading-6 text-gray-900"
										>
											Repita Nueva Contraseña
										</label>
									</div>
									<input
										id="confirm_password"
										name="confirm_password"
										type="password"
										autoComplete="current-password"
										required
										onChange={(event) => setConfirmPassword(event.target.value)}
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
									/>
								</div>
								<div>
									<button
										onClick={handlerOnClick}
										className="flex w-full justify-center rounded-md bg-blue-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
									>
										Cambiar contraseña
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FirstLogin
