import 'react-phone-number-input/style.css'

import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api'
import React, { useEffect, useRef, useState } from 'react'

import Input from 'react-phone-number-input/input'
import { Switch } from '@headlessui/react'
import axios from 'axios'
import { toast } from 'react-toastify'

// import PhoneInput from 'react-phone-number-input'

const libraries = ['places']

const FormActivist = ({
	name,
	last_name,
	email,
	address,
	phone,
	referentName,
	block,
	references,
	group,
	district,
	cityId,
	stateId,
	setIsValidated,
}) => {
	const inputRef = useRef()
	const [fullAddress, setFullAddress] = useState('')
	const [enabled, setEnabled] = useState(false)
	const [states, setStates] = useState([])
	const [loading, setLoading] = useState(true)
	const [cities, setCities] = useState([])
	const [stateSelected, setStateSelected] = useState(stateId.value)
	const handlePlaceChanged = () => {
		const [place] = inputRef.current.getPlaces()
		if (place) {
			const { formatted_address } = place
			setFullAddress(formatted_address)
			address.set({
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
			})
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		const URL = `${process.env.REACT_APP_API_URL}/activist/create`
		axios
			.post(URL, {
				name: name.value,
				last_name: last_name.value,
				email: email.value,
				address: address.value,
				phone: phone.value,
				full_address: fullAddress,
				reference_name: referentName.value,
				group: group.value,
				district: district.value,
				city_id: cityId.value,
				state_id: stateId.value,
			})
			.then((res) => {
				setIsValidated(true)
				toast.success('Registro exitoso', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				})
			})
			.catch((err) => {
				console.log(err)
				toast.error('Error, por favor intente nuevamente', {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'colored',
				})
			})
	}

	const [activists, setActivists] = useState([
		{
			id: 1,
			name: 'Apple',
		},
		{
			id: 2,
			name: 'Banana',
		},
		{
			id: 3,
			name: 'Orange',
		},
	])

	useEffect(() => {
		const getStates = async () => {
			await axios.get(`${process.env.REACT_APP_API_URL}/states`).then((res) => {
				setStates(res.data.states)
			})
		}

		try {
			getStates()
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}, [])

	useEffect(() => {
		setLoading(true)
		const getCities = async () => {
			await axios
				.get(`${process.env.REACT_APP_API_URL}/cities/${stateSelected}`)
				.then((res) => {
					setCities(res.data.cities)
				})
		}

		try {
			if (stateSelected) getCities()
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}, [stateSelected])

	const handleProvinciaChange = (e) => {
		const selectedState = states.find(
			(state) => state.id === parseInt(e.target.value),
		)
		stateId.set(selectedState.id)
		setStateSelected(selectedState.id)
		console.log(selectedState)
	}

	if (loading) {
		return (
			<div className="flex items-center justify-center h-screen">
				{' '}
				cargando...{' '}
			</div>
		)
	}

	return (
		<form className="space-y-6" action="#" method="POST">
			<div className="flex flex-wrap">
				<div className="w-1/2 pr-2">
					<label
						htmlFor="name"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Nombre
					</label>
					<div className="mt-2">
						<input
							id="name"
							name="name"
							type="text"
							autoComplete="name"
							required
							value={name.value}
							onChange={(e) => name.set(e.target.value)}
							className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						/>
					</div>
				</div>
				<div className="w-1/2 pl-2">
					<div className="flex items-center justify-between">
						<label
							htmlFor="last_name"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Apellido
						</label>
					</div>
					<div className="mt-2">
						<input
							id="last_name"
							name="last_name"
							type="text"
							autoComplete="last_name"
							value={last_name.value}
							onChange={(e) => last_name.set(e.target.value)}
							required
							className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						/>
					</div>
				</div>
			</div>

			<div>
				<div className="flex items-center justify-between">
					<label
						htmlFor="provincia"
						className="block text-sm font-medium text-gray-700"
					>
						Provincia
					</label>
				</div>
				<div className="mt-2">
					<select
						name="provincia"
						id="provincia"
						required
						className="block w-full mt-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						onChange={handleProvinciaChange} // Aquí podrías manejar el cambio si lo necesitas
					>
						<option value="" selected disabled>
							Selecciona una provincia
						</option>
						{states.map((state) => (
							<option value={state.id}>{state.name}</option>
						))}
					</select>
				</div>
			</div>

			<div>
				<div className="flex items-center justify-between">
					<label
						htmlFor="localidad"
						className="block text-sm font-medium text-gray-700"
					>
						Localidad
					</label>
				</div>
				<div className="mt-2">
					<select
						name="localidad"
						id="localidad"
						required
						className="block w-full mt-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						onChange={(e) => cityId.set(e.target.value)} // Aquí podrías manejar el cambio si lo necesitas
					>
						<option value="" disabled selected>
							Selecciona una localidad
						</option>
						{cities.map((city) => (
							<option value={city.id}>{city.name}</option>
						))}
					</select>
				</div>
			</div>

			<div>
				<div className="flex items-center justify-between">
					<label
						htmlFor="district"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Barrio
					</label>
				</div>
				<div className="mt-2">
					<input
						id="district"
						name="district"
						type="text"
						autoComplete="district"
						value={district.value}
						onChange={(e) => district.set(e.target.value)}
						required
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					/>
				</div>
			</div>

			<div>
				<div className="flex items-center justify-between">
					<label
						htmlFor="phone"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Telefono celular
					</label>
				</div>
				<div className="mt-2">
					<Input
						id="phone"
						name="phone"
						placeholder="Enter phone number"
						country="AR"
						international
						withCountryCallingCode
						value={phone.value}
						onChange={phone.set}
						required
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					/>
				</div>
			</div>

			<div>
				<div className="flex items-center justify-between">
					<label
						htmlFor="email"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Correo Electronico
					</label>
				</div>
				<div className="mt-2">
					<input
						id="email"
						name="email"
						type="email"
						autoComplete="email"
						value={email.value}
						onChange={(e) => email.set(e.target.value)}
						required
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					/>
				</div>
			</div>

			<div>
				<div className="flex items-center justify-between">
					<label
						htmlFor="email"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Agrupación
					</label>
				</div>
				<div className="mt-2">
					<input
						id="agrupacion"
						name="agrupacion"
						type="text"
						autoComplete="agrupacion"
						value={group.value}
						onChange={(e) => group.set(e.target.value)}
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					/>
				</div>
			</div>

			<div>
				<div className="flex items-center justify-between">
					<label
						htmlFor="referentNumber"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Conoces a un referente?
					</label>
				</div>
				<div className="flex items-center justify-start">
					<span className="block text-sm font-medium leading-6 text-gray-900">
						No
					</span>
					<Switch
						checked={enabled}
						onChange={setEnabled}
						className={`${
							enabled ? 'bg-blue-600' : 'bg-gray-200'
						} relative inline-flex h-6 w-11 items-center rounded-full m-3`}
					>
						<span className="sr-only">Enable notifications</span>
						<span
							className={`${
								enabled ? 'translate-x-6' : 'translate-x-1'
							} inline-block h-4 w-4 transform rounded-full bg-white transition`}
						/>
					</Switch>
					<span className="block text-sm font-medium leading-6 text-gray-900">
						Si
					</span>
				</div>

				{enabled && (
					<div className="mt-2">
						{/* <input
							id="referentName"
							name="referentName"
							type="text"
							placeholder="Nombre del referente"
							value={referentName.value}
							onChange={(e) => referentName.set(e.target.value)}
							required
							disabled={block}
							className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						/> */}
						<select
							id="referentName"
							name="referentName"
							type="text"
							placeholder="Nombre del referente"
							value={referentName.value}
							onChange={(e) => referentName.set(e.target.value)}
							required
							disabled={block}
							className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						>
							<option value="" selected disabled>
								Selecciona un referente
							</option>
							{references.map((reference) => (
								<option value={reference.id}>
									{reference.name + ' ' + reference.last_name}
								</option>
							))}
						</select>
					</div>
				)}
			</div>

			<div>
				<button
					type="button"
					onClick={handleSubmit}
					className="flex w-full justify-center rounded-md bg-blue-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
				>
					Unite
				</button>
			</div>
		</form>
	)
}

export default FormActivist
