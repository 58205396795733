import React, { useEffect, useState } from 'react'

import AppHeader from '../../../components/header'
import LoadingSpinner from '../../../components/loading'
import Table from '../../../components/table'
import { useSelector } from 'react-redux'

const ListReference = () => {
	const [data, setData] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const user = useSelector((state) => state.user.user)

	useEffect(() => {
		const URL = `${process.env.REACT_APP_API_URL}/reference/${user['id']}`
		fetch(URL)
			.then((response) => response.json())
			.then((data) => {
				const { reference } = data
				setData(reference['activists'])
				setIsLoading(false)
			})
	}, [user])

	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-screen min-w-full bg-gray-100 bg-opacity-50">
				<LoadingSpinner />
			</div>
		)
	}

	const URL = `${process.env.REACT_APP_APP_URL}/activist/${user.reference_code}`
	return (
		<>
			<AppHeader />
			<div
				style={{ backgroundColor: '#f4f4f4', width: 500, height: 150 }}
				className="flex flex-col self-center justify-between m-auto mt-4 border border-gray-300 rounded-xl shadow-lg p-4"
			>
				<h1 className="text-3xl font-semibold tracking-wide text-center">
					Bienvenido {user.name}
				</h1>
				<h3>
					Nro de referencia:{' '}
					<span className="font-semibold">{user['reference_code']}</span>
				</h3>
				<h3>
					<a href={URL}>{URL}</a>
				</h3>
			</div>

			<div className="m-auto flex justify-center align-center">
				<Table data={data} activists={true} />
			</div>
		</>
	)
}

export default ListReference
