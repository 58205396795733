import React from 'react'
import logo from '../../../assets/logo.png'
import { useNavigate } from 'react-router-dom'

const CreateActivist = () => {
	const navigate = useNavigate()

	return (
		<div className="flex min-h-full flex-1 flex-col justify-center py-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-xl">
				<div className="rounded-2xl shadow-2xl p-6 border border-gray-100 bg-gray-100">
					<div className="w-full flex justify-center">
						<img src={logo} className="h-36 self-center " alt="" />
					</div>
					<h1 className="text-2xl text-center">Bienvenidx!</h1>
					<p className="text-center my-2">
						Su registro con el referente se ha realizado con exito. Muchas
						gracias.
					</p>
					<div>
						<button
							type="click"
							onClick={() => navigate('/activist')}
							className="flex w-full justify-center rounded-md bg-blue-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Inicio
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CreateActivist
